.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2; /* slightly gray background */
  border-radius: 25px; /* rounded corners */
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); /* shadow effect */
  padding: 20px;
  max-height: 700px;
  width: 800px;
  margin: 0 auto;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to top right, #72abf2, #dddddd);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 600px) {
  .container {
    width: 90%;
    padding: 20px;
  }
}

.title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 32px;
}

@media screen and (max-width: 600px) {
  .title {
    font-size: 16px;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  font-size: 18px;
  margin: 10px 20px;
  padding: 10px 20px;
  background-color: #72abf2;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #dddddd;
  color: #333;
}

input {
  display: block;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  width: 250px;
  box-sizing: border-box;
}

.logo-top {
  left: 20px;
  height: 50px;
  background: #051554;
}

.container .logo-top {
  top: 20px;
  margin-bottom: 20px;
  left: 50%;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.verifyButton {
  background-color: #72abf2;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 18px;
}

.welcomeMessage {
  font-size: 16px;
  margin-bottom: 20px;
  margin: 0 20% 10px 20%;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .welcomeMessage {
    margin: auto auto;
    padding-bottom: 10px;
  }
}

/* .container h2 {
  font-size: 20px;
  margin-bottom: 20px;
  margin: 0 20% 10px 20%;
  text-align: center;
} */

.walletStatus {
  font-size: 16px;
  margin-bottom: 20px;
  margin: 0 20% 10px 20%;
  overflow-wrap: anywhere;
}

h2 {
  font-size: 20px;
  margin-bottom: 20px;
  margin: 0 10% 10px 10%;
  text-align: center;
}
